<template>
  <div>
    <v-card outlined class="secondary lighten-1 pa-3 rounded-t-xl rounded-b-0">
      <v-card color="white">
        <v-text-field
          class="text-center pa-2"
          v-model="block.name"
          label="Nome do Bloco"
          outlined
          dense
          hide-details
        />
        <v-btn @click="addFloor" color="white" small block>
          <v-icon small>mdi-plus</v-icon> Andar
        </v-btn>
      </v-card>
      <div class="d-flex flex-column gap-2 mt-4">
        <v-card
          color="secondary lighten-2"
          outlined
          class="d-flex gap-2 pa-1 align-center"
          v-for="(floor, i) in apartments"
          :key="i"
        >
          <b>{{ apartments.length - i }}</b>
          <v-btn icon @click="removeApartment(i)" class="white" x-small>
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <div
            class="d-flex gap-1 align-center justify-center flex-grow-1 flex-wrap"
            style="max-width: 200px"
          >
            <div class="apartment grey" v-for="n in floor" :key="`${i}-${n}`">
              {{ ({floor: apartments.length - i, number: n}) | apartmentFormat(block.apartmentFormat) }}
            </div>
          </div>
          <v-btn icon @click="addApartment(i)" class="white" x-small>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card>
      </div>
      <div class="mt-2">
        <v-btn @click="setFloorFormat" color="white" small block>
          Formato do Apartamento
        </v-btn>
      </div>
    </v-card>

    <v-dialog v-model="apartmentFormatModal" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Formato do Apartamento</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="apartmentFormat"
            label="Formato do Apartamento"
            outlined
            dense
            hide-details
          />

          <div class="d-flex flex-wrap gap-2 mt-4">
            <v-card outlined class="pa-4 flex-grow-1">
              <b>Legenda:</b>
              <p class="mb-0"><b>0-9</b> - Número fixo</p>
              <p class="mb-0"><b>F</b> - Número do andar iniciando em 1</p>
              <p class="mb-0"><b>T</b> - Número do andar iniciando em 0</p>
              <p class="mb-0"><b>A</b> - Número sequencial do apartamento</p>
            </v-card>
            <v-card outlined class="pa-4 flex-grow-1">
              <div class="d-flex gap-3 align-center">
                <b>Exemplo:</b>
                <span> Andar: 5 </span>
                <span> Apartamento: 3 </span>
              </div>
              <div class="d-flex gap-2 align-center justify-space-between">
                <p class="mb-0">Formato: F-A <br />Resultado: 5-3</p>
                <v-btn icon small @click="apartmentFormat = 'F-A'">
                  <v-icon>mdi-arrow-up-left-bold</v-icon>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div class="d-flex gap-2 align-center justify-space-between">
                <p class="mb-0">Formato: F0A <br />Resultado: 503</p>
                <v-btn icon small @click="apartmentFormat = 'F0A'">
                  <v-icon>mdi-arrow-up-left-bold</v-icon>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div class="d-flex gap-2 align-center justify-space-between">
                <p class="mb-0">Formato: FA <br />Resultado: 53</p>
                <v-btn icon small @click="apartmentFormat = 'FA'">
                  <v-icon>mdi-arrow-up-left-bold</v-icon>
                </v-btn>
              </div>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="apartmentFormatModal = false"
          >
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveApartmentFormat">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apartmentFormatModal: false,
      apartmentFormat: "",
    };
  },
  computed: {
    block: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    apartments: {
      get() {
        return this.block.apartments;
      },
      set(value) {
        this.block = { ...this.block, apartments: value };
      },
    },
  },
  methods: {
    addFloor() {
      this.apartments = [this.apartments[0] || 4, ...this.apartments];
    },
    removeApartment(floor) {
      this.apartments = this.apartments
        .map((f, i) => (i === floor ? f - 1 : f))
        .filter((f) => f > 0);
    },
    addApartment(floor) {
      this.apartments = this.apartments.map((f, i) =>
        i === floor ? f + 1 : f
      );
    },
    setFloorFormat() {
      this.apartmentFormatModal = true;
      this.apartmentFormat = this.block.apartmentFormat || "";
    },
    saveApartmentFormat() {
      this.block = { ...this.block, apartmentFormat: this.apartmentFormat };
      this.apartmentFormatModal = false;
    },
  },
};
</script>

<style>
.apartment {
  min-width: 20px;
  height: 22px;
  border: 1px solid black;
  border-radius: 2px;
  text-align: center;
  padding: 1px;
}
</style>
