<template>
  <div>
    <div class="d-flex mb-2">
      <p>
        {{ count.blocks }} bloco{{ count.blocks != 1 ? "s" : "" }} com
        {{ count.apartaments }} apartamento{{
          count.apartaments != 1 ? "s" : ""
        }}
      </p>
      <v-spacer />
      <v-btn @click="addBlock" color="primary">
        <v-icon left>mdi-plus</v-icon> Bloco
      </v-btn>
    </div>
    <div>
      <div class="d-flex gap-4 align-end" style="overflow-x: auto">
        <div
          v-for="(block, index) in condominium"
          :key="index"
          class="flex-shrink-0"
        >
          <block-editor v-model="condominium[index]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockEditor from "./BlockEditor.vue";
export default {
  components: { BlockEditor },
  methods: {
    addBlock() {
      const lastBlock = this.condominium[this.condominium.length - 1];
      const nameNumber = lastBlock ? lastBlock.name.match(/\d+/) : null;
      var name = "";
      if (nameNumber)
        name = lastBlock.name.replace(nameNumber, parseInt(nameNumber) + 1);

      console.log(nameNumber);

      this.condominium = [
        ...this.condominium,
        Object.assign(
          {},
          lastBlock || {
            apartments: new Array(4).fill(4),
          },
          { name }
        ),
      ];
    },
  },
  computed: {
    condominium: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    count() {
      const blocks = this.condominium.length;
      const apartaments = this.condominium.reduce(
        (acc, block) =>
          acc +
          parseInt(block.apartments.reduce((acc, floor) => acc + floor, 0)),
        0
      );
      return { blocks, apartaments };
    },
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
