<template>
  <div>
    <h3 class="mb-4">Configurar Loja {{ store?.Brand?.name }}</h3>
    <v-card class="pa-4" rounded="lg">
      <place-search
        v-model="storeData.address"
        label="Busque o nome do condominio ou endereço"
        outlined
      />
    </v-card>
    <v-card class="pa-4 mt-8" rounded="lg">
      <h4>Configurar Blocos e Apartamentos</h4>
      <condominium-editor v-model="storeData.condominium" class="px-2" />
      <v-alert
        color="success"
        dense
        class="mb-0"
        v-if="storeData.condominium.length"
      />
    </v-card>
    <div class="mt-8">
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
      <v-btn color="success" :loading="loading" @click="save">Salvar</v-btn>
    </div>
  </div>
</template>

<script>
import STORE from "@/services/store";
import { mapGetters } from "vuex";
import CondominiumEditor from "../../components/condominium/CondominiumEditor.vue";
import PlaceSearch from "../../components/global/PlaceSearch.vue";
export default {
  components: { CondominiumEditor, PlaceSearch },
  data: () => ({
    storeData: { address: {}, condominium: [] },
    loading: false,
    error: null,
  }),
  methods: {
    mountData() {
      if (!this.storeData.address?.name)
        throw new Error("Endereço não informado");
      if (!this.storeData.condominium || !this.storeData.condominium.length)
        throw new Error("Condominio não informado");

      if (this.storeData.condominium.some((c) => !c.apartments.length))
        throw new Error("Apartamentos não informados");
      if (this.storeData.condominium.some((c) => !c.name))
        throw new Error("Blocos sem nome");

      return {
        name: this.storeData.address.name,
        blocks: this.storeData.condominium,
        apartmentFormat: this.storeData.apartmentFormat,
        address: {
          street: this.storeData.address.street,
          number: this.storeData.address.number,
          complement: this.storeData.address.complement,
          neighborhood: this.storeData.address.neighborhood,
          city: this.storeData.address.city,
          state: this.storeData.address.state,
          zipCode: this.storeData.address.zipCode,
        },
      };
    },
    async save() {
      try {
        this.loading = true;
        this.error = null;
        const data = this.mountData();
        await STORE.init(this.store.id, data);
        this.$router.push({
          name: "store.index",
          params: { id: this.store.id },
        });
      } catch (error) {
        this.error = error.message || "Erro ao salvar loja";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("store", ["storeById"]),
    store() {
      return this.storeById(this.$route.params.id);
    },
  },
};
</script>

<style></style>
